import { render, staticRenderFns } from "./FieldSetButton.vue?vue&type=template&id=55ed478a&scoped=true&"
import script from "./FieldSetButton.vue?vue&type=script&lang=js&"
export * from "./FieldSetButton.vue?vue&type=script&lang=js&"
import style0 from "./FieldSetButton.vue?vue&type=style&index=0&id=55ed478a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ed478a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\冰点\\kems-xsm-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55ed478a')) {
      api.createRecord('55ed478a', component.options)
    } else {
      api.reload('55ed478a', component.options)
    }
    module.hot.accept("./FieldSetButton.vue?vue&type=template&id=55ed478a&scoped=true&", function () {
      api.rerender('55ed478a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/FieldSetButton.vue"
export default component.exports