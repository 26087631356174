<template>
  <div
    :class="[
      'kui-btn-group',
      !wrap && merge ? 'el-button-group' : ''
    ]"
  >
    <slot name="before"></slot>
    <template v-for="(btn,idx) in btnArr">
      <br v-if="idx!==0 && wrap" :key="idx+'br'" />
      <kui-prop
        :key="idx"
        tag="kui-button"
        :prop="btn"
        @click="btn.click"
      ></kui-prop>
    </template>
    <slot name="after"></slot>
  </div>
</template>

<script>
import KuiButtonGroup from "szkingdom.yf.koca-ui/packages/src/components/basic/KuiButtonGroup.vue";
export default {
  name: "KuiButtonGroupExt",
  extends: KuiButtonGroup,
};
</script>

<style scoped>

</style>
