var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: {
        "kframe-layout": true,
        "kframe-default-layout": true,
        "no-sidebar": true,
        tabnav: _vm.showFrameTags,
        fullscreen: _vm.sidebar.fullscreen,
      },
    },
    [
      _c("section", { staticClass: "frame-main" }, [
        _c("div", {
          ref: "kframe-layout-main-placeholder",
          staticClass: "kframe-layout-main-placeholder",
        }),
        _c(
          "div",
          {
            class: [
              _vm.prefixCls + "-multiple-header",
              _vm.prefixCls + "-multiple-header--fixed",
            ],
          },
          [
            _c(
              _vm.$settings.topbar.view,
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.fullscreen,
                    expression: "!fullscreen",
                  },
                ],
                tag: "component",
              },
              [
                _c("side-menu", {
                  class: ["kframe-menu--" + _vm.topbarSetting.theme],
                  attrs: {
                    data: _vm.menus,
                    collapse: !_vm.sidebar.opened,
                    mode: "horizontal",
                  },
                  on: { menuClickCallback: _vm.menuClickCallback },
                }),
              ],
              1
            ),
            _vm.showFrameTags
              ? _c(_vm.$settings.tagbar.view, { tag: "component" })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { class: [_vm.prefixCls + "-content", _vm.fullscreen ? "full" : ""] },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }