var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kui-form-page" }, [
    _c(
      "div",
      { staticClass: "form-left" },
      [
        _c(
          "kui-form-ext",
          _vm._b(
            {
              ref: "form",
              attrs: {
                model: _vm.model,
                "label-width": _vm.labelWidth,
                "item-width": _vm.itemWidth,
                items: _vm.items,
                "test-id": _vm.$attrs["test-id"],
                "is-test": _vm.$attrs["is-test"],
                "allow-test-components": _vm.$attrs["allow-test-components"],
                "test-suffix": _vm.$attrs["test-suffix"],
                "btns-inline": "",
                btns: _vm.searchBtns,
              },
            },
            "kui-form-ext",
            _vm.$attrs,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }