var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu-item",
    {
      staticClass: "kframe-menu-item",
      attrs: { index: _vm.item.menuId, title: _vm.item.menuName },
      on: { click: _vm.itemClick },
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("i", { class: _vm.item.menuIcon || "kui-icon-empty" }),
          _c("highlight-text", {
            staticClass: "menu-name",
            attrs: {
              text: _vm.$app.slider.opened
                ? _vm.item.menuName
                : _vm.item.abbreviate || _vm.item.menuName,
              highlight: _vm.highlightText,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }