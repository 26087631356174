import variables from "scss/mixin/layout-variables.scss";
import Vue from "vue";
import { MenuTypeEnum } from "szkingdom.yf.koca-template/lib/components/Settings/enum";
import SOCKJS, { Emitter } from "szkingdom.yf.koca-template/lib/http/sockjs";
import { app$, dict$, settings$ } from "szkingdom.yf.koca-template/lib/store";
import { setStyle } from "szkingdom.yf.koca-template/lib/utils/dom";
// import SideMenu from "szkingdom.yf.koca-template/lib/layout/components/SideMenu";
import SideMenu from "../components/SideMenu/index.vue";
import MenuFooter from "szkingdom.yf.koca-template/lib/layout/components/SideMenu/MenuFooter.vue";
import MenuHeader from "../components/SideMenu/MenuHeader.vue";
import MenuSearch from "szkingdom.yf.koca-template/lib/layout/components/SideMenu/MenuSearch.vue";
import menuMixin from "./MenuMixins/menu";
import menuSearchMixin from "szkingdom.yf.koca-template/lib/layout/mixins/MenuMixins/menu-search";
export default {
  components: {
    // FrameContainer
    SideMenu,
    MenuHeader,
    MenuFooter,
    MenuSearch
  },
  mixins: [menuMixin, menuSearchMixin],
  data () {
    return {
      activeMenuTab: "menu",
      prefixCls: "kframe-layout",
      variables
    };
  },
  computed: {
    sidebar () {
      return app$.slider;
    },
    sidebarSetting() {
      return settings$.menuSetting;
    },
    topbarSetting() {
      return settings$.topbarSetting;
    },
    showFrameTags () {
      // return process.env.VUE_APP_MULTIPLE_TAB === "1";
      return settings$.projectSetting.showTabs;
    },
    hasSiderFooter() {
      return settings$.siderFooter;
    },
    fullscreen() {
      return this.sidebar.fullscreen;
    }
  },
  methods: {
    async tabChange(tabName) {
      this.activeMenuTab = tabName;
      if (tabName === "favor") {
        await this.getFavoriteMenuList();
      }
    },
    setSiderStyle() {
      const { sidebarWidth, sidebarCollapsedWidth
      } = variables;

      const siderWidth = this.fullscreen ? "0px" : (this.sidebar.opened ? sidebarWidth : sidebarCollapsedWidth);


      const headerNode = this.$refs["kframe-layout-header"];
      setStyle(headerNode, "width", "calc(100% - " + siderWidth + ")");

      // 延时，node未渲染出来的情况
      this.$nextTick(() => {
        const siderNode = this.$refs["kframe-layout-sider"];
        setStyle(siderNode, {
          flex: "0 0 " + siderWidth,
          maxWidth: siderWidth,
          minWidth: siderWidth,
          width: siderWidth
        });
      });


      // fixed的占位元素
      const siderPlaceholder = this.$refs["kframe-layout-sider-placeholder"];
      setStyle(siderPlaceholder, {
        flex: "0 0 " + siderWidth,
        width: siderWidth,
        minWidth: siderWidth,
        maxWidth: siderWidth,
        overflow: "hidden",
        transition: "all 0.2s ease 0s",
      });
    },
    menuClickCallback(obj) {
      if (obj.activeMenuId) {
        app$.setTopMenu(obj.activeMenuId);
      }
      if (obj.activeTopMenuId) {
        app$.setTopMenu(obj.activeTopMenuId);
      }
      if (settings$.layout === MenuTypeEnum.MIX && obj.activeMenuId === obj.activeTopMenuId) {
        this.getMenuChildren(obj.activeMenuId);
      }
    },
    getMenuChildren(menuId) {
      const menu = this.menus.find((m) => m.menuId === menuId);
      this.sideMenuData = menu ? menu.children : [];
    },
    setMainPlaceHolderStyle() {
      const mainPlaceholder = this.$refs["kframe-layout-main-placeholder"];
      const mainPlaceholderHeight = this.fullscreen ? variables.tabnavHeight  : "calc(" + variables.topbarHeight + " + " + variables.tabnavHeight + ")";
      setStyle(mainPlaceholder,"height", mainPlaceholderHeight);
    }
  },
  created () {
    Emitter.$on("cache_received", (res) => {
      if(res && res.cacheName === "dictCache"){
        dict$.initDict();
      }
    });
    const settings = Vue.prototype.$settings;
    if(settings && settings.sockjs){
      const sockjs = settings.sockjs;
      if ( sockjs.enable) {
        const { connectUrl, broadcast, p2p } = sockjs;

        if(broadcast.enable || p2p.enable){
          const subscribed = [];
          if(broadcast.enable){
            subscribed.push(broadcast.subscribeUrl);
          }
          if(p2p.enable){
            subscribed.push(p2p.subscribeUrl);
          }
          this.socket = new SOCKJS({
            connectUrl: connectUrl,
            subscribes: subscribed
          });

        }
      }
    }
  },
  watch: {
    "sidebar.opened"(){
      this.setSiderStyle();
    },
    fullscreen() {
      this.setMainPlaceHolderStyle();
      this.setSiderStyle();
    }
  },
  mounted() {

    this.setMainPlaceHolderStyle();
    this.setSiderStyle();


  },
  beforeDestory () {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
};
