<template>
  <el-form
    ref="form"
    class="kui-form"
    :model="model"
    :inline="inline"
    :size="size"
    :disabled="disabled"
    :label-position="labelPosition"
    :label-suffix="labelSuffix"
    :hide-required-asterisk="hideRequiredAsterisk"
    :show-message="showMessage"
    :inline-message="inlineMessage"
    :status-icon="statusIcon"
    :rules="rules"
    :validate-on-rule-change="validateOnRuleChange"
    @validate="(prop, flag, msg) => $emit('validate', prop, flag, msg)"
    @submit.native.prevent
  >
    <slot>
      <template v-if="fieldArr">
        <kui-field
          v-for="(field, i) in fieldArr"
          :key="i"
          :title="field.title"
          closable
        >
          <template v-for="(item, idx) in field.items">
            <!-- inline为true时 强制换行 -->
            <br v-if="inline && item.wrap" :key="idx + 'br1'" />
            <kui-prop :key="idx" tag="kui-form-item" :prop="item">
              <slot v-if="$scopedSlots[item.prop]" :name="item.prop"></slot>
            </kui-prop>
          </template>
        </kui-field>
      </template>
      <template v-else-if="items">
        <template v-for="(item, idx) in items">
          <!-- inline为true时 强制换行 -->
          <br v-if="inline && item.wrap" :key="idx + 'br2'" />
          <kui-prop :key="idx" tag="kui-form-item" :prop="item" :ref="item.refName">
            <div
              v-if="$scopedSlots[item.prop + '_text']"
              :slot="item.prop + '_label'"
            >
              <slot :name="item.prop + '_text'"></slot>
            </div>
            <slot v-if="$scopedSlots[item.prop]" :name="item.prop" :item="item"></slot>
          </kui-prop>
        </template>
      </template>
    </slot>
    <template v-if="btns">
      <kui-form-item v-if="btnsInline" label=" ">
        <kui-button-group :btns="btns"></kui-button-group>
      </kui-form-item>
      <div v-else :class="['form-btns', btnsAlign ? 'btns-' + btnsAlign : '']">
        <kui-button-group :btns="btns"></kui-button-group>
      </div>
    </template>
  </el-form>
</template>


<script>
import KuiForm from "szkingdom.yf.koca-ui/packages/src/components/data/KuiForm.vue";

export default {
  name: "KuiFormExt",
  extends: KuiForm
};
</script>

<style scoped>

</style>
