var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.data, function (item, index) {
        return [
          item.children && item.children.length
            ? _c(
                "el-submenu",
                {
                  key: item.menuId,
                  class: [
                    _vm.activeTopMenuId === item.menuId
                      ? "menu-child-item-active"
                      : "",
                    "kframe-submenu",
                  ],
                  attrs: {
                    index:
                      _vm.lastVisibleIndex !== undefined &&
                      index > _vm.lastVisibleIndex
                        ? item.menuId + "-overflowed-flag"
                        : item.menuId,
                    "popper-class": "kframe-submenu-popper-list",
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      {
                        staticClass: "menu-name-wrapper",
                        style: { textIndent: _vm.levelPadding + "px" },
                        attrs: { title: item.menuName },
                      },
                      [
                        _c("i", { class: item.menuIcon || "kui-icon-empty" }),
                        _c("highlight-text", {
                          staticClass: "menu-name",
                          attrs: {
                            text: _vm.$app.slider.opened
                              ? item.menuName
                              : item.abbreviate || item.menuName,
                            highlight: _vm.highlightText,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("menu-tree", {
                    attrs: {
                      data: item.children,
                      "highlight-text": _vm.highlightText,
                    },
                  }),
                ],
                2
              )
            : _c("menu-item", {
                key: item.menuId,
                class: [_vm.activeTopMenuId === item.menuId ? "is-active" : ""],
                style: { textIndent: _vm.levelPadding + "px" },
                attrs: { item: item, "highlight-text": _vm.highlightText },
              }),
          _vm.mode === "horizontal"
            ? _c(
                "el-submenu",
                {
                  key: item.menuId + "-overflowed-submenu",
                  staticClass: "kframe-submenu kframe-menu-overflowed-submenu",
                  attrs: {
                    index: item.menuId + "-overflowed-submenu",
                    "popper-class":
                      "kframe-submenu-popper-list kframe-menu-popper",
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("i", {
                      staticClass: "kui-icon-more-fill",
                      staticStyle: { "margin-right": "auto" },
                    }),
                  ]),
                  _c("menu-tree", {
                    key: item.menuId,
                    attrs: { data: _vm.data.slice(_vm.lastVisibleIndex + 1) },
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }