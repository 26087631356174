import { menus$ } from "szkingdom.yf.koca-template/lib/store";

function getMenus( data ){
  // 过滤系统菜单
  const filtered = (data || []).filter( m => m.menuType === "0" && m.menuStatus === "0");
  return filtered.map( item => {
    return item.children && item.children.length ?
      {...item, children: getMenus(item.children)} :
      item;
  });
}
export default {
  computed: {
    menus() {
      return getMenus(menus$.menus);
    }
  }
};
