import { render, staticRenderFns } from "./MenuItem.vue?vue&type=template&id=5992824e&scoped=true&"
import script from "./MenuItem.vue?vue&type=script&lang=ts&"
export * from "./MenuItem.vue?vue&type=script&lang=ts&"
import style0 from "./MenuItem.vue?vue&type=style&index=0&id=5992824e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5992824e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\冰点\\kems-xsm-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5992824e')) {
      api.createRecord('5992824e', component.options)
    } else {
      api.reload('5992824e', component.options)
    }
    module.hot.accept("./MenuItem.vue?vue&type=template&id=5992824e&scoped=true&", function () {
      api.rerender('5992824e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/projects/kems-basic/views/layout/components/MenuItem.vue"
export default component.exports