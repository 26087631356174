var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "kframe-menu",
      attrs: {
        "default-active": _vm.activeMenuId,
        "default-openeds":
          _vm.defaultOpeneds && _vm.defaultOpeneds.length
            ? _vm.defaultOpeneds
            : undefined,
        collapse: _vm.mode === "horizontal" ? false : _vm.collapse,
        mode: _vm.mode,
        "menu-trigger": _vm.mode === "horizontal" ? "click" : "hover",
        "background-color": _vm.backgroundColor,
        "text-color": _vm.textColor,
        "active-text-color": _vm.activeTextColor,
        "collapse-transition": false,
      },
    },
    [
      _c("menu-tree", {
        attrs: {
          mode: _vm.mode,
          data: _vm.data,
          "highlight-text": _vm.highlightText,
          "last-visible-index": _vm.lastVisibleIndex,
          "active-top-menu-id": _vm.activeTopMenuId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }