var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: {
        "kframe-layout": true,
        "kframe-default-layout": true,
      },
    },
    [
      _c("section", { staticClass: "kframe-layout kframe-layout-has-sider" }, [
        _c("div", {
          ref: "kframe-layout-sider-placeholder",
          staticClass: "kframe-layout-sider-placeholder",
        }),
        _c(
          "aside",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.fullscreen,
                expression: "!fullscreen",
              },
            ],
            ref: "kframe-layout-sider",
            class: [
              _vm.prefixCls + "-sider",
              _vm.prefixCls + "-sider--" + _vm.sidebarSetting.theme,
              _vm.prefixCls + "-sidebar--sidebar",
              _vm.sidebar.opened ? "" : _vm.prefixCls + "-sider-collapsed",
              _vm.hasSiderFooter ? "sidebar-container--has-footer" : "",
            ],
          },
          [
            _c(
              "div",
              {
                staticClass: "logo",
                class: _vm.sidebar.opened ? "collapsed" : "",
              },
              [
                _c("img", {
                  class: _vm.sidebar.opened ? "collapsed" : "",
                  attrs: { src: _vm.logo },
                }),
                _vm.sidebar.opened
                  ? _c(
                      "span",
                      {
                        staticClass: "pro-name",
                        attrs: { title: _vm.proName },
                      },
                      [_vm._v(_vm._s(_vm.proName))]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              { staticClass: "menu-wrapper" },
              [
                _c(
                  "el-scrollbar",
                  {
                    staticClass: "sidebar-scrollbar",
                    attrs: { "delta-y": 20 },
                  },
                  [
                    _c("side-menu", {
                      key: "sidebar",
                      class: ["kframe-menu--" + _vm.sidebarSetting.theme],
                      attrs: {
                        "highlight-text": _vm.searchValue,
                        data:
                          _vm.searchValue === "" ? _vm.menus : _vm.searchResult,
                        collapse: !_vm.sidebar.opened,
                        "background-color": "" + _vm.sidebarSetting.bgColor,
                        "text-color": "#ffffff",
                      },
                      on: { menuClickCallback: _vm.menuClickCallback },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("menu-footer", { attrs: { opened: _vm.sidebar.opened } }),
          ],
          1
        ),
        _c("section", { class: [_vm.prefixCls, _vm.prefixCls + "-main"] }, [
          _c("div", {
            ref: "kframe-layout-main-placeholder",
            staticClass: "kframe-layout-main-placeholder",
          }),
          _c(
            "div",
            {
              ref: "kframe-layout-header",
              class: [
                _vm.prefixCls + "-multiple-header",
                _vm.prefixCls + "-multiple-header--fixed",
                _vm.prefixCls + "-multiple-header--" + _vm.topbarSetting.theme,
              ],
            },
            [
              _c(_vm.$settings.topbar.view, {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.fullscreen,
                    expression: "!fullscreen",
                  },
                ],
                tag: "component",
                on: { "on-collapsed-change": _vm.handleCollapsedChange },
              }),
              _c(_vm.$settings.tagbar.view, {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFrameTags,
                    expression: "showFrameTags",
                  },
                ],
                tag: "component",
                on: { "on-collapsed-change": _vm.handleCollapsedChange },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              class: [_vm.prefixCls + "-content", _vm.fullscreen ? "full" : ""],
            },
            [_vm._t("default")],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }