<template>
  <section
    :class="{
      'kframe-layout': true,
      'kframe-default-layout': true,
      'no-sidebar': true,
      tabnav: showFrameTags,
      fullscreen: sidebar.fullscreen,
    }"
  >
    <section class="frame-main">
      <!-- 顶部导航 -->
      <div
        ref="kframe-layout-main-placeholder"
        class="kframe-layout-main-placeholder"
      ></div>
      <div
        :class="[
          prefixCls + '-multiple-header',
          prefixCls + '-multiple-header--fixed',
        ]"
      >
        <component :is="$settings.topbar.view" v-show="!fullscreen">
          <side-menu
            :data="menus"
            :collapse="!sidebar.opened"
            mode="horizontal"
            :class="[`kframe-menu--${topbarSetting.theme}`]"
            @menuClickCallback="menuClickCallback"
          />
        </component>
        <component :is="$settings.tagbar.view" v-if="showFrameTags" />
      </div>
      <div :class="[`${prefixCls}-content`, fullscreen ? 'full' : '']">
        <slot></slot>
      </div>
    </section>
  </section>
</template>
<script>
// import layoutMixin from "../mixins/layout-mixin";
import layoutMixin from "@/projects/kems-basic/views/layout/mixins/layout-mixin";
export default {
  name: "TopMenu",
  mixins: [layoutMixin],
};
</script>
<style lang="scss" scoped>
@import "szkingdom.yf.koca-template/lib/layout/layout.scss";
</style>

