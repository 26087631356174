var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["kui-btn-group", !_vm.wrap && _vm.merge ? "el-button-group" : ""],
    },
    [
      _vm._t("before"),
      _vm._l(_vm.btnArr, function (btn, idx) {
        return [
          idx !== 0 && _vm.wrap ? _c("br", { key: idx + "br" }) : _vm._e(),
          _c("kui-prop", {
            key: idx,
            attrs: { tag: "kui-button", prop: btn },
            on: { click: btn.click },
          }),
        ]
      }),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }