<template>
  <div :class="['pro-info', collapsed ? 'collapsed' : '']">
    <!-- <img v-show="!collapsed" :src="logo" />
    <img v-show="collapsed" :src="collapsedLogo" /> -->

    <span class="pro-name" :title="proName">{{ proName }}</span>
  </div>
</template>
<script>
import { app$ } from "szkingdom.yf.koca-template/lib/store";
export default {
  props: {
    logo: {
      type: String,
      default: require("@/assets/logo-kingdom.png"),
    },
    collapsedLogo: {
      type: String,
      default: require("@/assets/logo-kingdom.png"),
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // logo() {
    //   if (this.collapsed && this.$settings.frameTop.smallLogo) {
    //     return this.$settings.frameTop.smallLogo;
    //   }
    //   return this.$settings.frameTop.logo;
    // },
    proName() {
      return app$.language === "en-US"
        ? process.env.VUE_APP_PRO_NAME_EN
        : process.env.VUE_APP_PRO_NAME;
    },
  },
};
</script>
<style lang="scss" scoped>
.pro-info {
  // float: left;
  height: $topbar-height;
  line-height: $topbar-height;
  user-select: none;
  width: $sidebar-width;
  display: flex;
  justify-content: flex-start;
  margin-left: 12px;
  align-content: center;
  align-items: center;
  img {
    // vertical-align: bottom;
    height: 32px;
  }
  &.collapsed {
    width: $sidebar-collapsed-width;
    justify-content: center;
    margin: auto;
    > img {
      margin-left: 0;
      // height: 24px;
    }
    .pro-name {
      display: none;
    }
  }
  .pro-name {
    font-family: SourceHanSansCN-Medium, SourceHanSansCN, Microsoft YaHei,
      sans-serif;
    margin-left: 4px;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #111111;
    white-space: nowrap;
    position: relative;
    bottom: -2px;
    // color: $--color-primary;
  }
}
</style>
