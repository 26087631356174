<template>
  <div class="menu-header">
    <logo :collapsed="!sidebar.opened" :logo="logo" />
    <!-- <div
      v-if="!collapse"
      :class="{ active: activeMenuTab === 'menu' }"
      @click="onTabChange('menu')"
    >
      <span>主菜单</span>
    </div>
    <div
      v-if="!collapse"
      :class="{ active: activeMenuTab === 'favor' }"
      @click="onTabChange('favor')"
    >
      <span>收藏</span>
    </div> -->
    <!-- <div class="menu-switch-container">
      <menu-switch />
    </div> -->
  </div>
</template>
<script>
import Logo from "../Logo.vue";
import { app$, settings$ } from "szkingdom.yf.koca-template/lib/store";
export default {
  name: "MenuHeader",
  components: { Logo },
  data() {
    return {
      activeMenuTab: "menu", // 菜单栏展示的内容： menu\favor\search
    };
  },
  computed: {
    sidebar() {
      return app$.slider;
    },
    logo() {
      // if (!this.sidebar.opened && this.$settings.frameTop.smallLogo) {
      //   return this.$settings.frameTop.smallLogo;
      // }
      return this.$settings.frameTop.logo;
    },
    textColor() {
      return settings$.menuSetting.theme === "light" ? "#111111" : "#ececec";
    },
  },

  methods: {
    onTabChange(tabName) {
      this.activeMenuTab = tabName;
      this.$emit("tab-change", tabName);
    },
  }
};
</script>
<style lang="scss" scoped>
@import "szkingdom.yf.koca-template/lib/assets/scss/mixin/_utils.scss";
.menu-header {
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  color: $--color-text-regular;
  height: $topbar-height;
  .menu-switch-container {
    // display: flex;
    // justify-content: end;
    cursor: pointer;
    width: $sidebar-collapsed-width;
    padding-top: 12px;
    text-align: center;
    vertical-align: middle;
    // margin-left: calc(#{$sidebar-width} - 36px);
    color: #fff;
    float: right;
    @include prefix(transition, all 0.28s);
    -webkit-tap-highlight-color: transparent;
    &:hover {
      color: $--color-primary;
    }
    &::after {
      content: ".";
      visibility: hidden;
      clear: both;
      display: block;
    }
  }
}
</style>
