var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["pro-info", _vm.collapsed ? "collapsed" : ""] }, [
    _c("span", { staticClass: "pro-name", attrs: { title: _vm.proName } }, [
      _vm._v(_vm._s(_vm.proName)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }