<template>
  <section
    :class="{
      'kframe-layout': true,
      'kframe-default-layout': true,
    }"
  >
    <!-- 顶部导航 -->
    <section class="kframe-layout kframe-layout-has-sider">
      <!-- 左侧导航 -->
      <div
        ref="kframe-layout-sider-placeholder"
        class="kframe-layout-sider-placeholder"
      ></div>
      <aside
        v-show="!fullscreen"
        ref="kframe-layout-sider"
        :class="[
          `${prefixCls}-sider`,
          `${prefixCls}-sider--${sidebarSetting.theme}`,
          `${prefixCls}-sidebar--sidebar`,
          sidebar.opened ? '' : `${prefixCls}-sider-collapsed`,
          hasSiderFooter ? 'sidebar-container--has-footer' : '',
        ]"
      >
        <div class="logo" :class="sidebar.opened ? 'collapsed' : ''">
          <img :class="sidebar.opened ? 'collapsed' : ''" :src="logo" />
          <span v-if="sidebar.opened" class="pro-name" :title="proName">{{
            proName
          }}</span>
        </div>
        <div class="menu-wrapper">
          <el-scrollbar class="sidebar-scrollbar" :delta-y="20">
            <side-menu
              key="sidebar"
              :highlight-text="searchValue"
              :data="searchValue === '' ? menus : searchResult"
              :collapse="!sidebar.opened"
              :class="[`kframe-menu--${sidebarSetting.theme}`]"
              :background-color="`${sidebarSetting.bgColor}`"
              text-color="#ffffff"
              @menuClickCallback="menuClickCallback"
            />
          </el-scrollbar>
        </div>
        <menu-footer :opened="sidebar.opened" />
      </aside>
      <section :class="[prefixCls, prefixCls + '-main']">
        <div
          ref="kframe-layout-main-placeholder"
          class="kframe-layout-main-placeholder"
        ></div>
        <div
          ref="kframe-layout-header"
          :class="[
            prefixCls + '-multiple-header',
            prefixCls + '-multiple-header--fixed',
            prefixCls + `-multiple-header--${topbarSetting.theme}`,
          ]"
        >
          <component
            :is="$settings.topbar.view"
            v-show="!fullscreen"
            @on-collapsed-change="handleCollapsedChange"
          />
          <component
            :is="$settings.tagbar.view"
            v-show="showFrameTags"
            @on-collapsed-change="handleCollapsedChange"
          />
        </div>
        <div :class="[`${prefixCls}-content`, fullscreen ? 'full' : '']">
          <slot></slot>
        </div>
      </section>
    </section>
  </section>
</template>
<script>
import SearchInput from "szkingdom.yf.koca-template/lib/layout/components/SideMenu/SearchInput.vue";
import layoutMixin from "@/projects/kems-basic/views/layout/mixins/layout-mixin";
import { app$ } from "szkingdom.yf.koca-template/lib/store/index";
import { findByCompetitionMark,searchFile } from "@/projects/kems-basic/api/login";
export default {
  name: "sidebar",
  components: { SearchInput },
  mixins: [layoutMixin],
  computed: {
    // proName() {
    //   return app$.language === "en-US"
    //     ? process.env.VUE_APP_PRO_NAME_EN
    //     : process.env.VUE_APP_PRO_NAME;
    // },
    // logo() {
    //   return this.$settings.frameTop.logo;
    // },
  },
  data() {
    return {
      competitionName: "",
      imgUrl : location.protocol + "//" + location.host,
      proName:"",
      logo:"",
    };
  },
  mounted() {
    if (window.localStorage.getItem("commtedId") != null) {
      let code = window.localStorage.getItem("commtedId");
      this.getComted(code);
    }else{
      this.proName='小算苗后台管理系统'
      this.logo=this.$settings.frameTop.logo
    }
  },
  methods: {
    handleCollapsedChange() {},
    getComted(code) {
      findByCompetitionMark(code).then((res) => {
        this.proName = res.data.competitionSimpleName;
        this.getFile(res.data.id);
      });
    },
    getFile(id) {
      searchFile(id, { objectType: 1 }).then((res) => {
        if (res.data.length > 0) {
          this.logo = this.imgUrl + res.data[0].uri;
        }else{
          this.logo=this.$settings.frameTop.logo
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "szkingdom.yf.koca-template/lib/layout/layout.scss";

$search-box-height: 53px; // 28px + 20px + 7px;
$menu-footer-height: 40px;

.#{$prefixCls} {
  // height: 100%;
  .menu-wrapper {
    height: calc(100%);
    width: 100%;
    overflow: hidden;
    .sidebar-scrollbar {
      height: calc(100% - 100px) !important;
    }
  }
  .main-menu,
  .#{$prefixCls}-menu-search + .menu-wrapper {
    height: calc(100%);
  }
  .sidebar-container--has-footer {
    // height: calc(100% - #{$topbar-height} - #{$sidebar-footer-height});
    padding-bottom: $sidebar-footer-height;
  }
}

.logo {
  padding: 0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.collapsed {
    padding: 0;
    text-align: left;
    vertical-align: middle;
  }
  img {
    vertical-align: middle;
    width: 100%;
    &.collapsed {
      width: 50px;
      height: 50px;
    }
  }
  .pro-name {
    vertical-align: middle;
  }
}
</style>
