<!--
 * @Description: 查询表单组件
 * @Author: zhubc
 * @Time: 2019-10-09 10:39:00
 * @LastAuthor: zhubc
 * @LastTime: 2019-11-26 09:02:37
 -->
<template>
  <div class="kui-form-page">
    <div class="form-left">
      <kui-form-ext
        ref="form"
        :model="model"
        :label-width="labelWidth"
        :item-width="itemWidth"
        :items="items"
        :test-id="$attrs['test-id']"
        :is-test="$attrs['is-test']"
        :allow-test-components="$attrs['allow-test-components']"
        :test-suffix="$attrs['test-suffix']"
        btns-inline
        v-bind="$attrs"
        :btns="searchBtns"
      ></kui-form-ext>
    </div>
<!--    <div class="form-right">
      <kui-button
        v-if="showMore"
        class="more-btn"
        :text="showMoreBtnText"
        type="text"
        @click="toggleMoreCondition"
      ></kui-button>
      <kui-button-group
        class="search-btn-group"
        :btns="searchBtns"
      ></kui-button-group>
      &lt;!&ndash; <kui-popover v-if="showMore" placement="bottom">
          <kui-button
            slot="reference"
            class="more-btn"
            text="$t[kui.form.more]"
            :type="null"
            plain
            icon-float="right"
            icon="el-icon-arrow-down"
            :is-test="$attrs['is-test'] || false"
            :test-id="$attrs['test-id'] || 'more-btn'"
          ></kui-button>
          <kui-check
            slot="content"
            v-model="moreValue"
            :opts="moreOptions"
            wrap
            :is-test="$attrs['is-test'] || false"
            :test-id="$attrs['test-id'] || 'more-check'"
          ></kui-check>
        </kui-popover> &ndash;&gt;
    </div>-->
  </div>
</template>
<script>
import { each, difference } from "lodash-es";
export default {
  name: "KuiFormPageExt",
  inheritAttrs: false,
  provide() {
    return {
      KuiFormPage: this
    };
  },
  props: {
    // 查询formmodel
    model: {
      type: Object,
      required: true
    },
    // 查询项
    items: {
      type: Array,
      required: true
    },
    // 查询项，label宽度
    labelWidth: {
      type: Number,
      default: 85
    },
    // 查询项宽度
    itemWidth: {
      type: Number,
      default: 255
    },
    // 重置数量，超过这个数字出现重置按钮
    resetNum: {
      type: Number,
      default: 4
    },
    // 隐藏查询项，超过这个数字，隐藏查询项
    hideNum: {
      type: Number,
      default: 8
    },
    // 查询块，其他按钮
    // 可以通过传递按钮来覆盖默认的查询按钮，重置按钮的属性。
    btns: {
      type: Array,
      default: () => ([])
    },
    // 是否开启回车搜索
    enableEnterSearch: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      moreValue: [],
      showMoreBtnText: "$t[kui.form.zhankai]"
    };
  },
  computed: {
    showMore() {
      return this.items.length > this.hideNum;
    },
    // moreOptions() {
    //   let opts = [];
    //   each(this.items, (item, idx) => {
    //     if (idx >= this.hideNum) {
    //       opts.push({ value: item.prop, label: item.label });
    //     }
    //   });
    //   return opts;
    // },
    searchBtns() {
      const btns = this.btns;
      const queryBtn = {
        key: "query",
        text: "$t[kui.form.query]",
        click: () => {
          this.$refs.form.valid((data) => {
            this.$emit("query", data);
          });
        },
        auth: this.$attrs["auth"]?.query,
        "is-test": this.$attrs["is-test"] || false,
        "test-id": this.$attrs["test-id"] || null
      };
      const i = btns.findIndex(obj => {
        return obj.key === "query";
      });
      if (i === -1) {
        btns.unshift(queryBtn);
      } else {
        btns[i] = Object.assign(queryBtn, btns[i]);
      }

      // let showMoreBtn = {
      //   key: "more",
      //   // text: "$t[kui.form.more]",
      //   type: "text",
      //   click: () => {
      //     this.toggleMoreCondition();
      //   },
      //   "is-test": this.$attrs["is-test"] || false,
      //   "test-id": this.$attrs["test-id"] || null
      // }
      // this.$set(showMoreBtn, "text", "$t[kui.form.more]")
      // if(this.items.length > this.hideNum) {
      //   let i = btns.findIndex(obj => {
      //     return obj.key === "more";
      //   });
      //   if (i === -1) {
      //     btns.unshift(showMoreBtn);
      //   } else {
      //     btns[i] = Object.assign(showMoreBtn, btns[i]);
      //   }
      // }
      if (this.items.length > this.resetNum) {
        const resetBtn = {
          key: "reset",
          type: null,
          icon: "kui-icon-refresh",
          click: () => {
            this.$refs.form.resetFields();
            this.$emit("restSearch");
          },
          title: this.$t("kui.form.reset"),
          auth: this.auth?.reset,
          "is-test": this.$attrs["is-test"] || false,
          "test-id": this.$attrs["test-id"] || null
        };
        const i = btns.findIndex(obj => {
          return obj.key === "reset";
        });
        if (i === -1) {
          btns.push(resetBtn);
        } else {
          btns[i] = Object.assign(resetBtn, btns[i]);
        }
      }
      return btns;
    }
  },
  watch: {
    moreValue(val, oldVal) {
      const add = difference(val, oldVal);
      const del = difference(oldVal, val);
      each(add, prop => {
        each(this.items, (item, idx) => {
          if (item.prop === prop) {
            item.visible = true;
          }
        });
      });
      each(del, prop => {
        each(this.items, (item, idx) => {
          if (item.prop === prop) {
            item.visible = false;
          }
        });
      });
    },
    // 异步items数据为异步获取时
    items() {
      this.renderItems();
    }

  },
  /* created() {
    this.renderItems();
  }, */
  mounted() {
    this.$nextTick(() => {
      this.formEl = this.$refs.form;
    });
  },
  methods: {
    renderItems() {
      let showNum = 0;
      each(this.items, (item, idx) => {
        if (item.visible === undefined || item.visible) {
          if (showNum < this.hideNum) {
            this.$set(this.items[idx], "visible", true);
          } else {
            this.$set(this.items[idx], "visible", false);
          }
        }
        if (item.visible) {
          showNum++;
        }
      });
    },
    toggleMoreCondition() {
      const hasHideItem = this.items.find(item => {
        return item.visible === false;
      });
      if (hasHideItem !== undefined) {
        this.showMoreBtnText = "$t[kui.form.shouqi]";
        this.items.forEach((item, index) => {
          if (index > this.hideNum - 1) {
            this.$set(item, "visible", true);
          }
        });
      } else {
        this.showMoreBtnText = "$t[kui.form.zhankai]";
        this.items.forEach((item, index) => {
          if (index > this.hideNum - 1) {
            this.$set(item, "visible", false);
          }
        });
      }
    }

  }
};
</script>
<style lang="scss" scoped>
.kui-form-page{
  display: flex;
  flex-direction: row;
  transition: all ease-in-out .3s;
  >.form-left{
    flex: 1;
    margin-right: 16px;
  }
  >.form-right {
    // flex: 1;
    display: flex;
    align-items: flex-start;
    .more-btn{
      margin-right: 16px;
    }
  }
}
</style>
