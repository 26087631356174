<template>
  <kui-popover placement="bottom-start" @show="showHandle(true)" @hide="showHandle(false)">
    <el-button ref="setButton"  style="font-size: 16px;" slot="reference"  icon="el-icon-s-grid" >
      <i class="el-icon-arrow-down"  :class="show ? '' : 'show'"></i>
    </el-button>
    <template v-slot:content>
      <div class="bottom-div" :style="{color: 'blue'}">
        <div class="bottom-div-item">
          <el-checkbox v-model="isSelectAll" @change="selectAllHandle">{{ '全选/不选' }}</el-checkbox>
        </div>
        <div v-for="(item,index) in _column" :key="item.prop + index" class="bottom-div-item">
          <el-checkbox v-model="item.show" @change="changeHandle">{{ item.label }}</el-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:title>
      <div>
        <kui-button plain size="small" type="success" @click="saveFieldSet">保存设置</kui-button>
      </div>
    </template>
  </kui-popover>
</template>

<script>
export default {
  name: "FieldSetButton",
  props: {
    column: {
      type:Array,
      default:() => []
    },
    moduleName: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _column: [],
      show: false,
      isSelectAll: true
    };
  },
  created() {
    this._column = this.column;
    if(this.moduleName) {
      const str = window.localStorage.getItem("fieldSet:" + this.moduleName);
      if(str) {
        const parse = JSON.parse(str);
        if(parse && parse.length>0){
          parse.forEach(one => {
            for (const index in this._column) {
              if(this._column[index].prop === one.prop) {
                this._column[index].show = one.show;
                break;
              }
            }
          });
        }
      }
    }
    this.isSelectAll = this._column.filter(item => !item.show).length  === 0;
    //t1odo 读取已有的配置
  },
  methods: {
    saveFieldSet() {
      this.$emit("save-field-set",this._column);
      this.$refs.setButton.$el.click();//隐藏下拉框
    },
    showHandle(show) {
      this.show = show;
    },
    selectAllHandle(bool) {
      this._column.forEach(item => item.show = bool);
      this.saveSet();
    },
    changeHandle(){
      this.isSelectAll = this._column.filter(item => !item.show).length  === 0;
      this.saveSet();
    },
    saveSet() {
      this.$nextTick(() => {
        if(this.moduleName) {
          window.localStorage.setItem("fieldSet:" + this.moduleName,JSON.stringify(this._column));
        }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .el-button--mini {
  padding: 7px 5px;
}

/deep/ .el-button [class*="el-icon-"] + span {
  margin-left: 0px;
}

.bottom-div{
  height: 260px;
  overflow: scroll;
  width: 140px;
  overflow-x: hidden;
  text-align: left;
  color: #1890ff;
}
.bottom-div-item {
  padding: 5px 0;
}
.bottom-div-item:hover {
  background-color: #E8E9EB;
}
.el-icon-arrow-down.show {
  transform: rotate(-180deg);
  transition-duration: 0.3s;
}
.el-icon-arrow-down {

  transform: rotate(0deg);
  transition-duration: 0.3s;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  /* 对应纵向滚动条的宽度 */
  width: 10px;
  /* 对应横向滚动条的宽度 */
  height: 10px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #1890ff;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #dddddd;
  border-radius: 32px;
}

</style>
