var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "kui-form",
      attrs: {
        model: _vm.model,
        inline: _vm.inline,
        size: _vm.size,
        disabled: _vm.disabled,
        "label-position": _vm.labelPosition,
        "label-suffix": _vm.labelSuffix,
        "hide-required-asterisk": _vm.hideRequiredAsterisk,
        "show-message": _vm.showMessage,
        "inline-message": _vm.inlineMessage,
        "status-icon": _vm.statusIcon,
        rules: _vm.rules,
        "validate-on-rule-change": _vm.validateOnRuleChange,
      },
      on: {
        validate: function (prop, flag, msg) {
          return _vm.$emit("validate", prop, flag, msg)
        },
      },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm._t("default", [
        _vm.fieldArr
          ? _vm._l(_vm.fieldArr, function (field, i) {
              return _c(
                "kui-field",
                { key: i, attrs: { title: field.title, closable: "" } },
                [
                  _vm._l(field.items, function (item, idx) {
                    return [
                      _vm.inline && item.wrap
                        ? _c("br", { key: idx + "br1" })
                        : _vm._e(),
                      _c(
                        "kui-prop",
                        {
                          key: idx,
                          attrs: { tag: "kui-form-item", prop: item },
                        },
                        [
                          _vm.$scopedSlots[item.prop]
                            ? _vm._t(item.prop)
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              )
            })
          : _vm.items
          ? [
              _vm._l(_vm.items, function (item, idx) {
                return [
                  _vm.inline && item.wrap
                    ? _c("br", { key: idx + "br2" })
                    : _vm._e(),
                  _c(
                    "kui-prop",
                    {
                      key: idx,
                      ref: item.refName,
                      refInFor: true,
                      attrs: { tag: "kui-form-item", prop: item },
                    },
                    [
                      _vm.$scopedSlots[item.prop + "_text"]
                        ? _c(
                            "div",
                            {
                              attrs: { slot: item.prop + "_label" },
                              slot: item.prop + "_label",
                            },
                            [_vm._t(item.prop + "_text")],
                            2
                          )
                        : _vm._e(),
                      _vm.$scopedSlots[item.prop]
                        ? _vm._t(item.prop, null, { item: item })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              }),
            ]
          : _vm._e(),
      ]),
      _vm.btns
        ? [
            _vm.btnsInline
              ? _c(
                  "kui-form-item",
                  { attrs: { label: " " } },
                  [_c("kui-button-group", { attrs: { btns: _vm.btns } })],
                  1
                )
              : _c(
                  "div",
                  {
                    class: [
                      "form-btns",
                      _vm.btnsAlign ? "btns-" + _vm.btnsAlign : "",
                    ],
                  },
                  [_c("kui-button-group", { attrs: { btns: _vm.btns } })],
                  1
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }